<template>
  <Page ref="page" :showReturn="true">
    <el-descriptions slot="tabs" :column="3" border style="margin-bottom: 20px">
      <el-descriptions-item label="料号">
        {{ info.code }}
      </el-descriptions-item>
      <el-descriptions-item label="名称">
        {{ info.name }}
      </el-descriptions-item>
      <el-descriptions-item label="规格">
        {{ info.spec }}
      </el-descriptions-item>
    </el-descriptions>

    <template slot="search">
      <el-form inline :model="params" ref="filtersForm">
        <el-form-item label="料号" prop="code">
          <el-input placeholder="请输入" v-model="params.code"></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input placeholder="请输入" v-model="params.name"></el-input>
        </el-form-item>
      </el-form>
    </template>

    <el-table
      :data="tableData"
      style="margin-bottom: 40px; width: 100%"
      row-key="id"
      border
      default-expand-all
      :tree-props="{ children: 'children' }"
      v-loading="loading"
      :row-class-name="tableRowClassName"
    >
      <el-table-column width="50" />
      <el-table-column align="center" label="序号" type="index" width="60" />
      <el-table-column
        show-overflow-tooltip
        prop="code"
        label="料号"
        width="150"
      />
      <el-table-column show-overflow-tooltip prop="name" label="名称" />
      <el-table-column show-overflow-tooltip prop="spec" label="规格" />
      <el-table-column
        show-overflow-tooltip
        label="备注"
        width="120"
        :formatter="({ remark }) => remark || '-'"
      />
      <el-table-column
        header-align="center"
        align="center"
        show-overflow-tooltip
        label="状态"
        width="80"
      >
        <template slot-scope="{ row }">
          {{ row.status | statusFilter }}
        </template>
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        show-overflow-tooltip
        label="变更时间"
        width="160"
        prop="syncTime"
      />
      <el-table-column
        header-align="center"
        align="center"
        label="是否可见"
        width="180"
      >
        <template slot-scope="{ row, $index }">
          <el-switch
            v-model="row.show"
            :active-value="1"
            :inactive-value="0"
            active-text="可见"
            inactive-text="隐藏"
			disabled
          ></el-switch>
        </template>
      </el-table-column>
    </el-table>
    <template slot="bottom">
      <el-button type="primary" @click="finishHandle" v-if="info.status === 2">
        处理完成
      </el-button>
    </template>
  </Page>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      tableData: [],
      params: {
        root: this.$route.query.root
      }
    }
  },
  computed: {
    info() {
      return this.$store.state.bomDetails || {}
    }
  },
  filters: {
    statusFilter: function (value) {
      const obj = { 1: '正常', 2: '差异', 3: '下线' }
      return obj[value] || value || '-'
    }
  },
  created() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        this.loading = true
        const list = await this.$api.bom.getBomDetail(this.params)
        this.tableData = this.$utils.transListToTreeData(list || [])
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async enableChange(v, row) {
      try {
        this.loading = true
        const res = await this.$api.bom.setBomEnable(row.id)
        this.$message.success('是否可见状态修改成功')
      } catch (err) {
        //可用状态修改失败时还原
        row.show = v === 1 ? 0 : 1
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    //处理完成事件
    async finishHandle() {
      try {
        this.loading = true
        let id = this.$route.query.root
        const res = await this.$api.bom.setBomFinish(id)
        this.$message.success('处理完成提交成功')
        this.$refs.page.returnEvent()
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.status === 2) {
        return 'danger-row'
      }
      if (row.status === 3) {
        return 'grey-row'
      }
      if (row.show === 1 && row.status === 1) {
        return 'success-row'
      }
      return ''
    }
  },
  beforeDestroy() {
    this.$store.commit('setState', { keyName: 'bomDetails', val: {} })
  }
}
</script>
